<template>
    <div class="text-tooltip">
        <el-tooltip
            class="item"
            :disabled="isShowTooltip"
            :content="content"
            :placement="placement"
            :open-delay="openDelay"
        >
            <p class="over-flow" :class="className" @mouseover="onMouseOver(refName)">
                <span ref="refConName">{{ contentName  }}</span>
                <span :ref="refName">{{ content || "--" }}</span>
            </p>
        </el-tooltip>
    </div>
</template>

<script>
export default {
    name: 'TextTooltip',
    props: {
        // 名称
        contentName: {
            type: String,
            default: ""
        },
        // 显示的文字内容
        content: {
            type: String,
            default: ""
        },
        // 位置
        placement: {
            type: String,
            default: "top"
        },
        // 外层框的样式，在传入的这个类名中设置文字显示的宽度
        className: {
            type: String,
            default: ""
        },
        // 为页面文字标识（如在同一页面中调用多次组件，此参数不可重复）
        refName: {
            type: String,
            default: ""
        },
        openDelay: {
            type: Number,
            default: 200
        }
    },
    data () {
        return {
            isShowTooltip: true
        }
    },
    methods: {
        onMouseOver (str) {
            let parentWidth = this.$refs[str].parentNode.offsetWidth;
            let contentWidth = this.$refs[str].offsetWidth;
            let contentNameWidth = this.$refs['refConName'].offsetWidth;
            // 若子集比中间层更宽 开启tooltip功能"
            if ((parentWidth - contentNameWidth) <= contentWidth) {
                this.isShowTooltip = false;
            } else { // 否则 关掉tooltip功能
                this.isShowTooltip = true;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.over-flow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

p {
    margin: 0;
}
</style>