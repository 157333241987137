<template>
    <div class="table-header">
            <div class="lines" v-if="item.labelDescription">
                <span>{{ item.label }}</span>
                <el-tooltip
                    effect="dark"
                    :content="item.labelDescription"
                    placement="top"
                    :offset="30"
                >
                    <i class="el-icon-question label-description"></i>
                </el-tooltip>
            </div>
        <text-tooltip
            v-else
            :content="item.label || ' '"
        ></text-tooltip>
    </div>
</template>

<script>
import {TextTooltip} from "common-local";
export default {
    name: "TableHeader",
    props: ["item", "align"],
    components: {
        TextTooltip,
    },
};
</script>

<style lang="scss" scoped>
.table-header {
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: bottom;
    .label-description {
        cursor: pointer;
        margin-left: 6px;
    }
    .label-description:hover {
        color: #177ee6;
    }
}
.lines{
    display: flex;
    align-items: center;
}
</style>
